import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react'
import { WALLET_INFO } from 'config/constants/wallet'
import { WalletType } from 'config/types/wallet'
import React from 'react'

export const SolanaWalletProvider = ({ endpoint, children }) => {
  const wallets = WALLET_INFO.filter((wallet) => wallet.type === WalletType.SOL).map((wallet) => wallet.adapter)

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        {children}
      </WalletProvider>
    </ConnectionProvider>
  )
}
