import { FetchingStatus } from 'config/constants'
import { RouteConfig } from 'config/constants/route'
import { useIsomorphicEffect } from 'hooks/useIsomorphicEffect'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useAppDispatch } from 'state'
import { initMetadata, setInitializeSiteStatus } from 'state/app/actions'
import { fetchMetaData } from 'state/app/calls/fetchMetaData'
import { useAuth } from 'state/auth/hooks'

const InitialSiteConfig = ({ children }) => {
  const router = useRouter()
  const dispatch = useAppDispatch()
  const [fetchingMetaStatus, setFetchingMetaStatus] = useState(FetchingStatus.Fetching)
  const { hasSession } = useAuth()

  useIsomorphicEffect(() => {
    const fetch = async () => {
      const meta = await fetchMetaData()

      if (meta && meta.code === 'error_country_banned') {
        setFetchingMetaStatus(FetchingStatus.Banned)
        dispatch(setInitializeSiteStatus({ status: FetchingStatus.Banned }))
        return
      }
      if (!meta || !meta.data) {
        setFetchingMetaStatus(FetchingStatus.Failed)
        dispatch(setInitializeSiteStatus({ status: FetchingStatus.Failed }))
        return
      }

      dispatch(
        initMetadata({
          data: meta.data,
        }),
      )

      setFetchingMetaStatus(FetchingStatus.Fetched)
    }

    fetch()
  }, [])

  useEffect(() => {
    if (fetchingMetaStatus === FetchingStatus.Banned && !router.pathname.includes(RouteConfig.Restriction)) {
      router.push(RouteConfig.Restriction)
    }
  }, [fetchingMetaStatus])

  useEffect(() => {
    if (!hasSession && fetchingMetaStatus !== FetchingStatus.Fetching) {
      dispatch(setInitializeSiteStatus({ status: FetchingStatus.Fetched }))
    }
  }, [hasSession, fetchingMetaStatus])

  return fetchingMetaStatus === FetchingStatus.Fetched && children
}

export default InitialSiteConfig
