import { useRouter } from 'hooks/useRouter'
import { useRef } from 'react'
import BonusService from 'services/BonusService'
import { useAppSelector } from 'state'
import { useAppStatus } from 'state/app/hooks'
import { useAuth } from 'state/auth/hooks'
import AutoRedeemBonusModal from 'views/AutoRedeemBonusModal'
import SignInToClaimBonusModal from 'views/AutoRedeemBonusModal/SignInToClaimBonusModal'
import { useIsomorphicEffect } from './useIsomorphicEffect'
import useModal from './useModal'
import useQueryParam from './useQueryParam'

const useAutoRedeemBonus = () => {
  const router = useRouter()
  const { isSigned, hasSession } = useAuth()
  const { removeParam } = useQueryParam()
  const [handlePresentAutoClaimBonus] = useModal(AutoRedeemBonusModal)
  const [presentSignUpToClaimBonus] = useModal(SignInToClaimBonusModal)

  const isAuthModalOpened = useRef(false)
  const isAuthModalOpen = useAppSelector((state) => state.app.isAuthModalOpen)
  const { isFinishFetch } = useAppStatus()

  useIsomorphicEffect(() => {
    const { bonus } = router.query

    if (isAuthModalOpen) {
      isAuthModalOpened.current = true
    }

    if (bonus && isFinishFetch && !hasSession && !isSigned && !isAuthModalOpen && !isAuthModalOpened.current) {
      const fetch = async () => {
        const bonusRes = await BonusService.getBonusDetails(bonus.toString()).call()

        if (bonusRes && bonusRes.data) {
          presentSignUpToClaimBonus({ bonus: bonusRes.data })
        } else removeParam('bonus')
      }
      const timeout = setTimeout(fetch, 1000)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [router.query.bonus, isFinishFetch, isSigned, isAuthModalOpen])

  useIsomorphicEffect(() => {
    const { bonus } = router.query

    if (bonus === '') {
      removeParam('bonus')
      return
    }

    if (!bonus || !isFinishFetch || !isSigned) return

    const handle = async () => {
      await removeParam('bonus')
      handlePresentAutoClaimBonus({ code: bonus.toString() })
    }

    handle()
  }, [router.query.bonus, isSigned, isFinishFetch])
}

export default useAutoRedeemBonus
