import { FetchingStatus } from 'config/constants'
import { useEffect, useState } from 'react'
import { getGameLevelStats } from 'services/mapper/utils'
import { UserDataResponse } from 'services/types'
import { useAppDispatch } from 'state'
import { setInitializeSiteStatus, updateTokenUsdPrices } from 'state/app/actions'
import { fetchTokenInUsdPrices } from 'state/app/calls/fetchPriceInUsd'
import { useTiers } from 'state/app/hooks'
import { fetchUserData } from 'state/auth/calls/fetchUserData'
import { useAuth } from 'state/auth/hooks'
import { updateActivatedBonuses, updateUserUnlockableHUSDAmount } from 'state/bonus/actions'
import { updateNotificationUnreadAmount } from 'state/notification/actions'
import { initialProfile } from 'state/profile/actions'
import { forkjoinRequest } from 'utils/requestHelper'

const InitialUserProfile = ({ children }) => {
  const dispatch = useAppDispatch()
  const [fetchingMetaStatus, setFetchingMetaStatus] = useState(FetchingStatus.Fetching)
  const { isSigned } = useAuth()

  const tiers = useTiers()

  useEffect(() => {
    if (!isSigned) {
      return
    }

    const fetch = async () => {
      const [user, prices] = await forkjoinRequest([fetchUserData(), fetchTokenInUsdPrices()])
      const { activeBonus, profile, balances, welcomePackage, notificationUnreadCount, bonusBalances } =
        user.data as UserDataResponse

      if (activeBonus.length > 0) {
        dispatch(
          updateActivatedBonuses({
            activatedBonuses: activeBonus,
          }),
        )
      }

      const dataTier = tiers.find((tier) => tier.id === profile.tier)
      const wager = getGameLevelStats(tiers, dataTier, profile.totalWager)

      dispatch(
        initialProfile({
          data: {
            balances,
            profile: {
              tier: dataTier,
              wager,
            },
            welcomePackage,
          },
        }),
      )

      dispatch(
        updateUserUnlockableHUSDAmount({
          unlockableBalance: bonusBalances.HUSD,
        }),
      )

      dispatch(
        updateNotificationUnreadAmount({
          personal: notificationUnreadCount?.personal || 0,
          system: notificationUnreadCount?.system || 0,
        }),
      )

      if (prices) dispatch(updateTokenUsdPrices({ data: prices.data }))

      setFetchingMetaStatus(FetchingStatus.Fetched)
      dispatch(setInitializeSiteStatus({ status: FetchingStatus.Fetched }))
    }

    fetch()
  }, [isSigned, tiers])

  return fetchingMetaStatus !== FetchingStatus.Fetching && children
}

export default InitialUserProfile
