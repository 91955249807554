import Box from 'components/Box/Box'
import Button from 'components/Button/Button'
import Image from 'components/Image/Image'
import Dots from 'components/Loader/Dots'
import Modal, { ModalBody, ModalProps } from 'components/Modal'
import OpenEffect from 'components/OpenEffect'
import Text from 'components/Text/Text'
import { RouteConfig } from 'config/constants/route'
import { useIsomorphicEffect } from 'hooks/useIsomorphicEffect'
import { useRouter } from 'hooks/useRouter'
import { ColumnCenter } from 'layout/Components/Column'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useAuth } from 'state/auth/hooks'
import styled from 'styled-components'
import base from 'theme/base'
import { useRedeemBonus } from 'views/BonusCenter/hooks'

interface AutoRedeemBonusModalProps {
  code: string
}

enum ClaimStatusEnum {
  Claiming,
  Failed,
  Succeed,
}
const AutoRedeemBonusModal: React.FC<ModalProps<AutoRedeemBonusModalProps>> = ({ data, onDismiss }) => {
  const { code } = data
  const { isSigned } = useAuth()
  const [claimStatus, setClaimStatus] = useState(ClaimStatusEnum.Claiming)
  const [error, setError] = useState('')
  const { t } = useTranslation()
  const redeem = useRedeemBonus()
  const router = useRouter()

  const handleRedeemBonus = async () => {
    const modalbackdrop = window.document.getElementById('modal-backdrop')
    if (modalbackdrop) {
      modalbackdrop.style.pointerEvents = 'none'
    }
    setClaimStatus(ClaimStatusEnum.Claiming)
    const response = await redeem(code, 2000)

    if (response) {
      if (response.data) {
        setClaimStatus(ClaimStatusEnum.Succeed)
      } else {
        if (response.code === 'error_data_not_found') {
          setError(t('Oops! This bonus code is invalid or has already been used!'))
        } else if (response.code === 'error_data_closed') {
          setError(t('The bonus code has been fully claimed!'))
        } else if (response.code === 'error_invalid_data') {
          setError(t("You don't meet the conditions of the promotion!"))
        }

        setClaimStatus(ClaimStatusEnum.Failed)
      }
    }

    if (modalbackdrop) {
      modalbackdrop.style.pointerEvents = 'all'
    }
  }

  useIsomorphicEffect(() => {
    if (isSigned && code) {
      handleRedeemBonus()
    }
  }, [code, isSigned])

  return (
    <Modal
      onDismiss={onDismiss}
      disableClose={claimStatus === ClaimStatusEnum.Claiming}
      minWidth={['100% !important', '100% !important', '468px !important']}
      maxWidth={['100% !important', '100% !important', '468px !important']}
      p={0}
    >
      <StyledModalBody p="24px 24px 40px 24px" overflowX="hidden" position="relative">
        {claimStatus === ClaimStatusEnum.Claiming && (
          <StyledContainer mt="24px">
            <Box width={120} height={120}>
              <Image src="/images/voucher_gift.png" width={240} height={240} />
            </Box>
            <ColumnCenter mt="26px">
              <Text fontSize="20px" fontWeight={700} mb={2} color="text">
                <Trans>Just a moment</Trans>!
              </Text>
              <Text small color="textTertiary" fontWeight={400}>
                <Dots>Your bonus is being delivered to you</Dots>
              </Text>
            </ColumnCenter>
          </StyledContainer>
        )}

        {claimStatus === ClaimStatusEnum.Failed && (
          <StyledContainer mt="24px">
            <Box width={80} height={80}>
              <Image src="/images/error-icon.png" width={160} height={160} />
            </Box>

            <Text fontSize="20px" fontWeight={800} mt={20} mb={2}>
              Oops!
            </Text>

            <Box p="12px" borderRadius="5px">
              <Text small textAlign="center" lineHeight="18px" color="textTertiary">
                {error}
              </Text>
            </Box>

            <Button
              onClick={() => {
                onDismiss()
              }}
              height="48px"
              mt="40px"
              width="312px"
              style={{
                borderRadius: base.radii.tiny,
              }}
            >
              <Text small bold>
                <Trans>Close</Trans>
              </Text>
            </Button>
          </StyledContainer>
        )}

        {claimStatus === ClaimStatusEnum.Succeed && (
          <StyledContainer mt="24px">
            <Box width={80} height={80}>
              <Image src="/images/success-icon-alt.png" width={160} height={160} />
            </Box>

            <ColumnCenter mt="12px" p="12px" borderRadius="5px">
              <Text fontSize="20px" fontWeight={800} mb={2}>
                <Trans>Success</Trans>!
              </Text>

              <Text small lineHeight="18px" color="textTertiary" maxWidth={420} textAlign="justify">
                <Trans>
                  Congrats! The bonus has been added to your account. You can go to Bonus Center and use it now!
                </Trans>
              </Text>
            </ColumnCenter>

            <Button
              onClick={() => {
                router.push(RouteConfig.BonusCenter)
                onDismiss()
              }}
              height="48px"
              mt="24px"
              width="312px"
              style={{
                borderRadius: base.radii.tiny,
              }}
            >
              <Text small bold>
                <Trans>Bonus center</Trans>
              </Text>
            </Button>
          </StyledContainer>
        )}
      </StyledModalBody>
    </Modal>
  )
}

const StyledModalBody = styled(ModalBody)`
  max-height: ${({ theme: { topbarHeight } }) => `calc(var(--screen-height) - ${topbarHeight})`};
`

const StyledContainer = styled(OpenEffect).attrs({ openType: 'grow' })`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default AutoRedeemBonusModal
