import { useEffect } from 'react'
import { useAppDispatch } from 'state'
import { setIsSigned } from 'state/app/actions'
import { useAuth, useAuthRefresh } from 'state/auth/hooks'

const AuthenticationProvider = ({ children }) => {
  const { isSigned, signedAtTime, hasSession } = useAuth()
  const refresh = useAuthRefresh()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!isSigned && !hasSession) dispatch(setIsSigned({ isSigned: false }))
    if (isSigned || !hasSession) return

    refresh()
  }, [hasSession, isSigned])

  useEffect(() => {
    if (!isSigned) return

    if (!signedAtTime) dispatch(setIsSigned({ isSigned: false }))
    else if (new Date().getTime() - signedAtTime > 240000) dispatch(setIsSigned({ isSigned: false }))
    else {
      const timeout = setTimeout(() => {
        dispatch(setIsSigned({ isSigned: false }))
      }, new Date().getTime() - signedAtTime + 240000)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [signedAtTime, isSigned])

  return isSigned && children
}

export default AuthenticationProvider
