import { FetchingStatus } from 'config/constants'
import useActionQueryListener from 'hooks/useActionQueryListener'
import useAutoRedeemBonus from 'hooks/useAutoRedeemBonus'
import useDebounce from 'hooks/useDebounce'
import { useInitChatbox } from 'hooks/useInitChatbox'
import { useUpdateScreenHeight } from 'hooks/useUpdateScreenHeight'
import { useRouter } from 'next/router'
import AuthenticationProvider from 'providers/AuthenticationProvider'
import InitialUserProfile from 'providers/InitialUserProfile'
import UserBackgroundService from 'providers/UserBackgroundService'
import { useTranslation } from 'react-i18next'
import { useAppStatus, useMenuToggleUpdater } from 'state/app/hooks'
import { usePollSystemData } from 'state/system/hooks'

export const StateUpdater = () => {
  usePollSystemData()
  const { status } = useAppStatus()

  return (
    <>
      <AuthenticationProvider>
        {status !== FetchingStatus.Banned &&
          status !== FetchingStatus.Maintenance &&
          status !== FetchingStatus.Failed && (
            <>
              <InitialUserProfile>
                <UserBackgroundService />
              </InitialUserProfile>
            </>
          )}
      </AuthenticationProvider>
    </>
  )
}

export const GlobalUpdater = () => {
  const { isFinishFetch } = useAppStatus()
  const router = useRouter()
  const { i18n } = useTranslation()

  const isAppReady = useDebounce(isFinishFetch && router.locale === i18n.language, 500)

  useUpdateScreenHeight()
  useMenuToggleUpdater()
  useInitChatbox()

  return isAppReady && <ParamsHandler />
}

const ParamsHandler = () => {
  useAutoRedeemBonus()
  useActionQueryListener()

  return null
}
