import { configureScope, setUser } from '@sentry/nextjs'
import { useEffect } from 'react'
import { useUserInfo } from 'state/auth/hooks'

function useSentryUser() {
  const { username, uid } = useUserInfo()

  useEffect(() => {
    if (username && uid) {
      setUser({ username, uid })
    } else {
      configureScope((scope) => scope.setUser(null))
    }
  }, [username, uid])
}

export default useSentryUser
