import { GlobalUpdater, StateUpdater } from 'Updater'
import Loader from 'components/Loader'
import { TOAST_CONFIG } from 'config/constants'
import ModalsProvider from 'contexts/Modals'
import { SolanaWalletProvider } from 'contexts/Solana/SolanaWalletProvider'
import { useIsomorphicEffect } from 'hooks/useIsomorphicEffect'
import resources from 'i18n/resources'
import i18n from 'i18next'
import { useRouter } from 'next/router'
import { WagmiProvider } from 'packages/wagmi/WagmiProvider'
import { wagmiConfig } from 'packages/wagmi/wagmi'
import InitialServiceDetails from 'providers/InitialServiceDetails'
import InitialSiteConfig from 'providers/InitialSiteConfig'
import { useEffect, useState } from 'react'
import { initReactI18next, useTranslation } from 'react-i18next'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor } from 'state'
import { useSolNetworkInfo } from 'state/app/hooks'
import { ThemeProvider } from 'styled-components'
import GlobalStyle from 'styles/Global'
import ResetCSS from 'styles/ResetCSS'
import theme from 'theme'
import { getLangCodeByBCP47 } from 'utils'
import { getCookie } from 'utils/cookie'

const Providers = ({ store, children }) => {
  return (
    <I18nProvider>
      <ThemeProvider theme={theme}>
        <ResetCSS />
        <GlobalStyle />
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Web3Provider>
              <ModalsProvider>
                <InitialServiceDetails>
                  <InitialSiteConfig>
                    <StateUpdater />
                  </InitialSiteConfig>
                </InitialServiceDetails>
                <GlobalUpdater />

                {children}
                <ToastContainer {...TOAST_CONFIG} />

                <Loader />
              </ModalsProvider>
            </Web3Provider>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </I18nProvider>
  )
}

const I18nProvider = ({ children }) => {
  const router = useRouter()

  const [isLanguageInitialized, setIsLanguageInitialized] = useState(false)

  useIsomorphicEffect(() => {
    const init = async () => {
      await i18n.use(initReactI18next).init({
        resources,
        lng:
          router.locale === 'default'
            ? getCookie('APP_LANG') || getLangCodeByBCP47(navigator.language) || 'en'
            : router.locale,
        interpolation: {
          escapeValue: false,
        },
      })
      setIsLanguageInitialized(true)
    }
    init()
  }, [])

  return isLanguageInitialized && <I18nEventListener> {children}</I18nEventListener>
}

const I18nEventListener = ({ children }) => {
  const router = useRouter()
  const { i18n } = useTranslation()

  useEffect(() => {
    const { pathname, asPath, query, locale } = router
    if (i18n.language === locale) return

    router.push({ pathname, query }, asPath, { locale: i18n.language })
  }, [i18n.language, router.locale])

  return children
}

const Web3Provider = ({ children }) => {
  const solNetwork = useSolNetworkInfo()

  return (
    <WagmiProvider config={wagmiConfig}>
      <SolanaWalletProvider endpoint={solNetwork.rpcCollections[0]}>{children}</SolanaWalletProvider>
    </WagmiProvider>
  )
}

export default Providers
