import useAnalyticsUser from 'hooks/useAnalyticsUser'
import useSentryUser from 'hooks/useSentryUser'
import { usePollTokenUsdPrices } from 'state/app/hooks'
import { useRemainAccessTokenTimelife } from 'state/auth/hooks'
import { useActivatedBonusesUpdater } from 'state/bonus/hooks'
import { useCashbackUpdater } from 'state/cashback/hooks'
import { useListenPrivateNotification, useListenSystemNotification } from 'state/notification/hooks'
import {
  useInitializeUserFavoriteGames,
  useInitializeWageringBonusGames,
  usePollUserBalances,
  useUpdateUserToken,
  useUserProfileUpdater,
} from 'state/profile/hooks'
import { usePollPendingTransaction } from 'state/transaction/hooks'

const UserBackgroundService = () => {
  usePollTokenUsdPrices()
  usePollUserBalances()
  usePollPendingTransaction()
  useRemainAccessTokenTimelife()
  useUserProfileUpdater()
  useActivatedBonusesUpdater()
  useListenPrivateNotification()
  useListenSystemNotification()
  useUpdateUserToken()
  useSentryUser()
  useAnalyticsUser()
  useCashbackUpdater()
  useInitializeWageringBonusGames()
  useInitializeUserFavoriteGames()

  return null
}

export default UserBackgroundService
