import { FetchingStatus } from 'config/constants'
import { RouteConfig } from 'config/constants/route'
import { useIsomorphicEffect } from 'hooks/useIsomorphicEffect'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useAppDispatch } from 'state'
import { setInitializeSiteStatus } from 'state/app/actions'
import { initSystemData } from 'state/system/actions'
import { fetchSystemData } from 'state/system/calls/fetchSystemData'
import { useSystemData } from 'state/system/hooks'

const InitialServiceDetails = ({ children }) => {
  const router = useRouter()
  const dispatch = useAppDispatch()
  const systemData = useSystemData()

  const [fetchingStatus, setFetchingStatus] = useState(FetchingStatus.Fetching)

  useIsomorphicEffect(() => {
    dispatch(setInitializeSiteStatus({ status: FetchingStatus.Fetching }))
    const fetchSystem = async () => {
      const systemData = await fetchSystemData()

      dispatch(
        initSystemData({
          isEnableMaintenanceMode: systemData?.isEnableMaintenanceMode,
          serviceOpenAt: systemData.openAt,
          serviceShutdownAt: systemData.shutDownAt,
        }),
      )
    }
    fetchSystem()
  }, [])

  useEffect(() => {
    if (systemData.isServiceShutdown && router.pathname !== RouteConfig.Maintenance) {
      router.push(RouteConfig.Maintenance)
      dispatch(setInitializeSiteStatus({ status: FetchingStatus.Maintenance }))
    } else {
      setFetchingStatus(FetchingStatus.Fetched)
    }
  }, [systemData.isServiceShutdown])

  return fetchingStatus === FetchingStatus.Fetched && children
}

export default InitialServiceDetails
