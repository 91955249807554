import Box from 'components/Box/Box'
import Button from 'components/Button/Button'
import Image from 'components/Image/Image'
import NextImageLoaderCallback from 'components/Image/NextImageLoaderCallback'
import Modal, { ModalBody, ModalProps } from 'components/Modal'
import OpenEffect from 'components/OpenEffect'

import Text from 'components/Text/Text'
import { CommonBonus } from 'config/types/bonus'
import useAuthenticationModal from 'hooks/useAuthenticationModal'
import { useIsomorphicEffect } from 'hooks/useIsomorphicEffect'
import { ColumnCenter } from 'layout/Components/Column'
import { useState } from 'react'
import { Trans } from 'react-i18next'
import { useAppSelector } from 'state'
import styled from 'styled-components'
import { useDisplayBonusName } from 'views/BonusCenter/hooks'

const SignInToClaimBonusModal: React.FC<ModalProps<{ bonus: CommonBonus }>> = ({ onDismiss, data: { bonus } }) => {
  const isAuthModalOpen = useAppSelector((state) => state.app.isAuthModalOpen)
  const [onPresentAuthModal] = useAuthenticationModal()

  const [isAssetsLoaded, setIsAssetsLoaded] = useState(false)
  const [name] = useDisplayBonusName(bonus)

  useIsomorphicEffect(() => {
    if (isAuthModalOpen) onDismiss()
  }, [isAuthModalOpen])

  return (
    <>
      <NextImageLoaderCallback
        imgs={[{ src: '/images/bonusCenter/auto-redeem-bonus-icon.png', width: 120, height: 120 }]}
        onLoaded={() => setIsAssetsLoaded(true)}
      />
      <Modal
        onDismiss={onDismiss}
        maxWidth="468px !important"
        minWidth="468px !important"
        minHeight="398px !important"
        p="0px !important"
      >
        <StyledModalBody p="24px  24px  40px 24px" overflowX="hidden" position="relative">
          <ColumnCenter mt="24px">
            {isAssetsLoaded && (
              <Box width={120} height={120}>
                <OpenEffect openType="grow">
                  <Image src="/images/bonusCenter/auto-redeem-bonus-icon.png" width={240} height={240} />
                </OpenEffect>
              </Box>
            )}

            <ColumnCenter as={OpenEffect} openType="grow">
              <Text fontSize={['16px', '', '20px']} fontWeight={700} color="text" mt="20px" mb="4px" lineHeight="24px">
                <Trans>Exclusive Bonus Awaits</Trans>!
              </Text>

              <Text
                fontSize={['12px', '', '14px']}
                color="textTertiary"
                lineHeight="18px"
                maxWidth={420}
                textAlign="center"
              >
                <Trans
                  i18nKey="autoRedeemBonusTitle"
                  components={{ highlight_tag: <Text color="text" fontSize="inherit" fontWeight={700} as="span" /> }}
                  values={{ bonusName: bonus.name || name }}
                />
              </Text>

              <Button
                variant="primary"
                maxWidth="312px !important"
                width="100%"
                mt="40px"
                onClick={() => {
                  onPresentAuthModal()
                  onDismiss()
                }}
              >
                <Text fontSize={['12px', '', '14px']} color="text" fontWeight={700}>
                  <Trans>Log in or Sign up to claim</Trans>
                </Text>
              </Button>
            </ColumnCenter>
          </ColumnCenter>
        </StyledModalBody>
      </Modal>
    </>
  )
}

const StyledModalBody = styled(ModalBody)`
  max-height: ${({ theme: { topbarHeight } }) => `calc(var(--screen-height) - ${topbarHeight})`};
`

export default SignInToClaimBonusModal
