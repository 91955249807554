import { RouteConfig } from 'config/constants/route'
import { CRISP_ID } from 'config/constants/server'
import { useEffect } from 'react'
import { useAppStatus } from 'state/app/hooks'
import { useElementContainerChatboxRendered, useIconChatHandle } from './useCrisp'
import { useRouter } from './useRouter'

const routerHideChatBox = [RouteConfig.Spin, RouteConfig.SpinToWin]

export const useInitChatbox = () => {
  const { isFinishFetch } = useAppStatus()
  const elementContainerChatbox = useElementContainerChatboxRendered()
  const { hideIconChat, visibleIconChat } = useIconChatHandle()
  const router = useRouter()

  useEffect(() => {
    if (isFinishFetch) {
      window.CRISP_WEBSITE_ID = CRISP_ID
      ;(function initCript() {
        const d = document
        const s = d.createElement('script') as any

        s.src = 'https://client.crisp.chat/l.js'
        s.async = 1
        d.getElementsByTagName('head')[0].appendChild(s)
      })()
    }
  }, [isFinishFetch])

  useEffect(() => {
    if (elementContainerChatbox) {
      if (!routerHideChatBox.includes(router.route as RouteConfig)) {
        visibleIconChat()
      } else {
        hideIconChat()
      }
    }
  }, [elementContainerChatbox, router])
}
